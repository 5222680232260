import App from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import React from 'react';
import { config as faConfig } from '@fortawesome/fontawesome-svg-core';
import { CloudinaryContext } from 'cloudinary-react';
import * as Sentry from '@sentry/node';
import { buildURL } from 'react-imgix';
import withAnalytics from 'next-analytics';

import config from 'config';

import '@fortawesome/fontawesome-svg-core/styles.css';
import 'style/style.css';

// Inject the FontAwesome CSS globally to avoid icon flicker
faConfig.autoAddCss = false;

// Sentry error tracking
Sentry.init({
  dsn: config('/sentry/dsn'),
  enabled: config('/production'),
});

class MyApp extends App {
  render() {
    // eslint-disable-next-line no-unused-vars
    const { Component, pageProps, err, apolloClient } = this.props;

    const image = config('/opengraph/image');
    const imageUrl = image.replace(config('/mediaUrls/s3'), config('/mediaUrls/imgix'));
    const facebookImage = buildURL(imageUrl, {
      disableLibraryParam: true,
      aggressiveLoad: true,
      w: 1200,
      h: 627,
      q: 80,
      crop: 'faces,edges',
      fit: 'crop',
    });

    const twitterImage = buildURL(imageUrl, {
      disableLibraryParam: true,
      aggressiveLoad: true,
      w: 280,
      h: 150,
      q: 80,
      crop: 'faces,edges',
      fit: 'crop',
    });

    return (
      <CloudinaryContext cloudName={config('/cloudinary/cloudName')}>
        <Head>
          <meta charSet="utf-8" key="charset" />
          <meta
            key="viewport"
            name="viewport"
            content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no,shrink-to-fit=no"
          />

          <title>{config('/opengraph/title')}</title>

          <meta key="description" name="description" content={config('/opengraph/description')} />

          <meta key="og-type" name="og:type" content="website" />
          <meta key="og-url" name="og:url" content={config('/opengraph/url')} />
          <meta key="og-title" name="og:title" content={config('/opengraph/title')} />
          <meta
            key="og-description"
            name="og:description"
            content={config('/opengraph/description')}
          />
          <meta key="og-image" name="og:image" content={facebookImage} />

          <meta key="twitter-card" name="twitter:card" content="summary" />
          <meta key="twitter-url" name="twitter:url" content={config('/opengraph/url')} />
          <meta key="twitter-title" name="twitter:title" content={config('/opengraph/title')} />
          <meta
            key="twitter-description"
            name="twiter:description"
            content={config('/opengraph/description')}
          />
          <meta key="twitter-image" name="twitter:image" content={twitterImage} />

          <link rel="icon" href={config('/favicon')} type="image/png" />
          <link rel="shortcut icon" href={config('/favicon')} type="image/png" />

          <link
            rel="stylesheet"
            href="https://rhm-assets.s3.us-east-2.amazonaws.com/fonts/artifexcf/extralight/stylesheet.css"
          />
          <link rel="stylesheet" href="https://use.typekit.net/dsl3uru.css" />
          <script
            defer
            src="https://umami.globobeet.org/script.js"
            data-website-id="a2c9d653-c8a1-40f4-9804-a678ceafb0ed"
          />
        </Head>
        <Component {...pageProps} err={err} />
      </CloudinaryContext>
    );
  }
}

export default withAnalytics(Router, config('/analytics'))(MyApp);
